import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
 

//主路由
const routes: Array<RouteRecordRaw> = [
   
  {
    path: '/',
    name: 'certView',
    component: () => import('../views/certView.vue')
  }, 
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})




export default router
